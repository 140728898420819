import React from 'react'
import { Link } from 'react-router-dom'

/*
    Fußzeile mit Copyright und AGBs
*/

const Footer = () => {
    return (
        <footer>
            Copyright &copy; 2023
            <Link className="nav-link active" aria-current="page" to="/agbs">AGBs</Link>
        </footer>
    )
}

export default Footer
