import React from 'react'
import { FaTimes } from 'react-icons/fa'
import { DayToString } from '../DayToString';

const Nachricht = ({ meldung, onDelete, bearbeiten }) => {
    //<div style={{ backgroundImage: `url(${PortaitSandra})` }} className="news-image float-lg-end mx-auto mx-lg-5" />

    var datum = new Date(meldung.datum);
    var bild = meldung.bild;

    return (

        <div className="randBox">
            <div>
                <div>
                    <ul className="list-group-flush">
                        <h1 className="list-group-item">
                            {meldung.titel} {bearbeiten && <FaTimes style={{ cursor: 'pointer' }} onClick={() => onDelete(meldung.id)} />}
                        </h1>
                        <h6 className="float-lg-end">
                            <DayToString date={datum} />
                        </h6>
                        <h3>
                            {meldung.extra}
                        </h3>
                        <p>
                            {bild &&
                                <div style={{ backgroundImage: `url(${bild})` }} className="news-image float-lg-end mx-auto mx-lg-5" />}
                            {meldung.text}
                        </p>
                    </ul>

                </div>
            </div>
        </div>

    )
}

export default Nachricht
