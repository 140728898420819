import React from 'react'
import Hobbit from "../../assets/Alpaka_Portrait/Hobit.jpeg";
import Caleb from "../../assets/Alpaka_Portrait/Caleb.jpeg";
import Eana from "../../assets/Alpaka_Portrait/Eana.jpeg";
import Estrella from "../../assets/Alpaka_Portrait/Estrella.jpeg";
import Candy from "../../assets/Alpaka_Portrait/Candy.jpg";
import Kalieder from "../../assets/Alpaka_Portrait/Kalieder.jpeg";
import BlackDilara from "../../assets/Alpaka_Portrait/BlackDilara.jpg";
import BlackPearl from "../../assets/Alpaka_Portrait/BlackPearl.jpg";
import BlackPrinzess from "../../assets/Alpaka_Portrait/BlackPrinzess.jpg";
import MaryPopins from "../../assets/Alpaka_Portrait/MaryPopins.jpeg"



const Tiere = () => {
    return (
        <>
            <div className="randBox">
                <div style={{ backgroundImage: `url(${Candy})` }} className="about-image float-lg-end mx-auto mx-lg-5" />
                <h1>Candy</h1>
                <p>
                    Farbe: weiß <br/><br/>
                    Unsere weiße Lady, immer etwas erhaben und stolz und immer sauber. Nicht immer einfach einzufangen. Wer es schafft sie einzufangen und zu halftern bekommt einen Preis. <br/> Aber eine sehr gute Mama.
                </p>
            </div>
            <div className="randBox">
                <div style={{ backgroundImage: `url(${Kalieder})` }} className="about-image float-lg-start mx-auto mx-lg-5" />
                <h1>Calida</h1>
                <p>
                    Farbe: braun <br/><br/>
                    Eine 100% Tochter von Candy, sehr stolz, immer sehr sauber und immer kurz vorm Verhungern. Gerne geht unsere Dame auch schwimmen und sie mag es sogar gestreichelt zu werden, was bei einem Alpaka nicht selbstverständlich ist! 
                </p>
            </div>
            <div className="randBox">
                <div style={{ backgroundImage: `url(${Caleb})` }} className="about-image float-lg-end mx-auto mx-lg-5" />
                <h1>Caleb</h1>
                <p>
                    Farbe: appalossa <br/><br/>
                    Der Sohn von Candy, auch Candy's Prinz genannt. Manchmal rufen wir ihn auch "Cookie" da seine Fellfarbe an einen Keks erinnert. 
                    Er ist ein absoluter Hingucker. 
                    Immer gut drauf und immer neugierig. 
                    Beim Laufen ist er auch gern vorne. 
                    Das wird mal ein Anführer. 
                    Am Futtertrog spielt er gerne den Futterwart und bekommt dann seinen speziellen Blick...
                </p>
            </div>
            <div className="randBox">
                <div style={{ backgroundImage: `url(${Hobbit})` }} className="about-image float-lg-start mx-auto mx-lg-5" />
                <h1>Hobbit</h1>
                <p>
                    Farbe: light fawn <br/><br/>
                    Unser Prinz Charming, sobald irgendwo eine Kamera auftaucht.. zack ist er zur Stelle. Immer fotogen und sehr geduldig. Hobbit läuft mit Kindern genauso zuverlässig wie mit Erwachsenen und immer so, das alle mitkommen. Dabei ist ihm auch die Fellpflege äußert wichtig, ab und an mal wälzen muss schon sein.  Einfach ein Traum Alpaka. 
                </p>
            </div>
            <div className="randBox">
                <div style={{ backgroundImage: `url(${Estrella})` }} className="about-image float-lg-end mx-auto mx-lg-5" />
                <h1>Estrella</h1>
                <p>
                    Farbe: braun <br/><br/>
                    Wird auch gerne Trulla genannt. Die Chefin im Stall und auf der Weide. Wehe jemand kommt ihr zu Nahe oder geht an ihr Futter, da kennt unsere Trulla keinen Spaß und spuckt auch mal gerne.
                    Mit Vorliebe werden Hunde angespuckt, die hinter dem Zaun Rabatz machen. Sie ist aber auch absolut zuverlässig beim Laufen und die perfekte Anführerin. Solange man da läuft wo sie laufen will...
                </p>
            </div>
            <div className="randBox">
                <div style={{ backgroundImage: `url(${Eana})` }} className="about-image float-lg-start mx-auto mx-lg-5" />
                <h1>Eanna</h1>
                <p>
                    Farbe: braun <br/><br/>
                    Estrellas Tochter, auch Hummelchen genannt ist genauso selbstbewusst wie ihre Mutter. Selbst der Deckhengst bekam das zu spüren :). Ein durch und durch lustiges, wildes Mädchen. 
                    Niemals launenhaft ist Eanna unser aller Liebling, die auch gerne Küsse verteilt.
                </p>
            </div>
            <div className="randBox">
                <div style={{ backgroundImage: `url(${BlackDilara})` }} className="about-image float-lg-end mx-auto mx-lg-5" />
                <h1>Black Dilara</h1>
                <p>
                    Farbe: schwarz<br/><br/>
                    Auch Blacky genannt, besticht durch ihr ruhiges, ausgegelichenes Wesen. Eine wunderschöne Stute. Absolut pflegeleicht im Umgang, ist kein Kostverächter und beherscht eine perfekte Spucktechnik.  Hier sollte man den Spruch beachten: Immer schön seitlich stehen...
                </p>
            </div>
            <div className="randBox">
                <div style={{ backgroundImage: `url(${BlackPearl})` }} className="about-image float-lg-start mx-auto mx-lg-5" />
                <h1>Black Pearl</h1>
                <p>
                    Farbe: schwarz<br/><br/>
                    Tochter von Black Dilara auch Büffelchen geannnt ist ein wundervolles Tier. Sie nimmt alles gelassen hin und bleibt immer ruhig. 
                    Egal ob nun geschoren wird oder Fußpediküre oder oder oder. Pearl ist immer ein nervenstarkes Mädel.
         
                </p>
            </div>
            <div className="randBox">
                <div style={{ backgroundImage: `url(${BlackPrinzess})` }} className="about-image float-lg-end mx-auto mx-lg-5" />
                <h1>Black Prizess</h1>
                <p>
                    Farbe: schwarz<br/><br/>
                    Tochter von Black Dilara, unser "Drachenmädchen Cleo" ein wunderschönes Alpaka mit einem kleinen weißen Fleck am Bauch. Kam schon groß zur Welt und ist ein Wildfang. Ständig in Bewegung und kaum zu bändigen.
                </p>
            </div>
            <div className="randBox">
                <div style={{ backgroundImage: `url(${MaryPopins})` }} className="about-image float-lg-start mx-auto mx-lg-5" />
                <h1>Mary Poppins</h1>
                <p>
                    Farbe: dunkelbraun<br/><br/>
                    Spitzname Püppi, ein wunderschönes, kleines Goldstück. Anfangs etwas schüchtern, aber gerne am rumtollen und rumhüpfen. Sie kann mit den Augen rollen, das einem schwindelig wird.
                </p>
            </div>
            

            
        </>
    )
}

export default Tiere
