import React from 'react'

export const Alpaka_Patnerschaft = () => {
    return (
        <div className="randBox" id="patenschaft">
            <h4 className="list-group-item">Alpaka Patenschaft</h4>
            <p className="mx-4">
                <li>Wer möchte kann ein Jahr lang „Patentante“ oder „Patenonkel“ eines Alpakas werden.
                    Sie können dann ein Jahr lang das ausgesuchte Tier regelmäßig besuchen, die Fütterung mitmachen und damit das Tier im Jahresverlauf besser kennen lernen.
                    Spannend wird das natürlich besonders bei den tragenden Stuten.
                    Das ist die spannendste Zeit im Jahr. Wer bringt was zur Welt?
                    Ein kleines Hengstfohlen oder ein kleine Stute? Übrigens nennt man die Alpaka
                    Kinder „Cria“. Auch die Zeit des Scherens ist spannend.</li>
                <li>inkl. Patenurkunde</li>
                <li>1 Spaziergang pro Quartal inkl. einer Begleitperson</li>
                <li>1 monatliche Entspannungsstunde auf der Alpakawiese inkl. einer Begleitperson</li>
                <li>100€ pro Jahr </li>
            </p>
        </div>
    )
}