import React from 'react'

export default function HaltungInfo() {
    return (
        <div className='randBox'>
            <h3>Haltung</h3>
            Alpakas sind unkompliziert in der Haltung. Sie sind zäh und robust. Aus ihrer Heimat den Anden sind sie extreme klimatische Verhältnisse gewöhnt. Es kommen in ihrer Heimat am selben Tag hohe Plus und Minustemperaturen vor. Unser Klima ist daher problemlos für Alpakas. Alpakas sind Herdentiere. Deshalb sollten mindestens zwei Tiere gehalten werden. Alpakas benötigen einen ganzjährigen Weidegang.
            <br /><br/>
            Auf ein Hektar können ca. 12 - 15 Alpakas gehalten werden. Eine Einzäunung aus Knotengitter von ca. 140-160cm Höhe reicht problemlos aus. Ein Offenstall mit einer mindestfläche von 2 qm pro Tier ist empfehlenswert, damit die Tiere selber wählen können,
            ob sie sich drinnen oder draussen aufhalten wollen. Alpakas rupfen das Gras nicht und sind somit ausgesprochene Landschaftspfleger.
            <br /><br/>
            In den Sommermonaten müssen die Tiere jederzeit einen schattigen Platz aufsuchen können. Einen Sandplatz in dem sie sich wälzen können, nehmen sie gerne an. Alpakas sind Schwielensohler, das heisst, sie laufen auf zwei Ledersohlen, und verletzen somit die Grasnarbe viel weniger als andere Weidetiere. Alpakas legen Kotplätze an und beugen so dem Parasitenbefall auf der Weide vor. Alpakas sind mit Gras am besten zufrieden zu stellen. Heu muss immer zur freien Verfügung angeboten werden. Das Rauhfutter hat eine spezielle Bedeutung bei der Verdauung und ist unverzichtbar. Frisches Wasser muss immer ausreichend zur Verfügung stehen. Gelegentliche Kraftfutterzugaben können bei Zuchtstuten oder Jungstuten nötig sein.
            <br /><br/>
            Man sollte mit dem Kraftfutter alledings sparsam sein und nicht zuviel füttern. Mineralien müssen immer zur Verfügung stehen. Alpakas werden im Frühjahr geschoren. Die Haltung von mehreren Hengsten in einer Stutenherde ist nicht möglich. Der Leithengst duldet keinen weiteren geschlechtsreifen Hengst in der Herde. Die Haltung von mehreren geschlechtsreifen Hengsten in einer Hengstherde ist problemlos möglich.
        </div>
    )
}
