import React from 'react'
import icon from '../assets/icon.jpeg'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';

/*
  Navigation durch die Seiten mit Smartphone-Unterstüßung
*/

const NavBar = () => {
  return (
    <Navbar bg="light" expand="lg" sticky="top">
      <Container>
        <Navbar.Brand href="/">
          <img src={icon} className="icon" />
          Alpakas am Forsterwald
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link href="/">Startseite</Nav.Link>
            <Nav.Link href="/news">News</Nav.Link>
            <Nav.Link href="/informationen">Informationen</Nav.Link>
            <Nav.Link href="/kontakt">Kontakt</Nav.Link>
            <NavDropdown href="/termine" title="Termine" id="basic-nav-dropdown">
              <NavDropdown.Item href="/termine#schnuppern">Alpaka Schnuppern</NavDropdown.Item>
              <NavDropdown.Item href="/termine#zeit">Alpaka Zeit</NavDropdown.Item>
              <NavDropdown.Item href="/termine#spaziergang">Alpaka Spaziergang</NavDropdown.Item>
              <NavDropdown.Item href="/termine#fruehstueck">Alpaka Frühstück</NavDropdown.Item>
              <NavDropdown.Item href="/termine#patenschaft">Alpaka Patenschaft</NavDropdown.Item>
            </NavDropdown>
            <Nav.Link href="/tiere">Unsere Tiere</Nav.Link>
            <Nav.Link href="/wissenswertes">Wissenswert</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}

export default NavBar
