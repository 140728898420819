import React from 'react'
import { useState, useEffect } from 'react'
import Nachrichten from './Nachrichten';
import { getMs } from '../datenbank/DatenbankInterface'

const News = () => {
  /**
   * Hauptseite der Unterseite News
   * Hier können schnell und einfach neue Mitteilungen gepostet werden.
   * 
   * Überschrift gibt an wenn es keine Neuigkeiten gibt
   * Daten werden aus Datenbank gezogen
   * 
   * ToDo:
   * jeder Post soll auch ein Bild besitzten können
   * uploaden, speichern und einbinden kostenlos? 
   */


  //Container mit dem man einen neuen Eintrag erstellen kann anzeigen
  //const [showNeuerEintrag, setShowNeu] = useState(false);

  //Ermöglicht das gezielte Löschen von 
  //const [bearbeiten, setBearbeiten] = useState(false);

  // Meldungen aus der Datenbank
  const [nachrichten, setNachrichten] = useState([]);

  // beim Laden der Unterseite /news werden Daten aus der Datenbank geladen
  useEffect(() => {
    const getNachrichten = async () => {

      try {
        //ausgelagerter Datenbankzugriff
        const nachrichtenFromServer = await getMs();

        //meldungen zwischen speichern
        setNachrichten(nachrichtenFromServer);
        
      } catch (error) {
      }

      
    }

    getNachrichten()
  }, [])

  return (
    <div>
      <div className="randBox">
        <h1 align="center">{nachrichten.length > 0 ? "Alle wichtigen Neugikeiten auf einen Blick" : "Im Moment gibt es keine News"}</h1>
      </div>

      <Nachrichten nachrichten={nachrichten}/>

    </div >
  )
}

export default News