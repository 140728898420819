import Nachricht from './Nachricht'

const Nachrichten = ({nachrichten, onDelete, bearbeiten}) => {  

  return (
    <>
      {nachrichten.map((meldung, index) => (
        <Nachricht key={index} meldung={meldung} onDelete={onDelete} bearbeiten={bearbeiten}/>
      ))}
    </>
  )
}

export default Nachrichten