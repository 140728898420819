import React from 'react';
import PortaitSandra from "../../assets/Banner/Sandra_kniet.jpeg";
import { GMaps } from './GMaps';

const Kontakt = () => {

    /**
     *  Erster Container mit Bild und Daten von Sandra
     * 
     *  Zweiter Container mit Anfart (Beschreibung, Adresse, Gmaps)
     */
    return (
        <div>
            <div className="randBox">

                <div style={{ backgroundImage: `url(${PortaitSandra})` }} className="about-image float-lg-end mx-auto mx-lg-5" />
                <h1 align="center">Kontakt</h1>
                <h3><br />Inhaberin </h3>
                <h3>Alexandra Schmidt</h3>
                <h3>Mobil: <a href="tel:+49-162-8694605">0162/8694605</a></h3>
                <h6>Gerne über WhatsApp oder am Wochende telefonisch</h6>
            </div>
            <div className="randBox">
                <h1 align="center">Anfahrt</h1>
                <ul className="list-group list-group-flush">
                    <h6 className="list-group-item">Adresse für das Navi:</h6>
                    <p className="mx-4">
                        61206 Wöllstadt <br />
                        Waldhof 1
                    </p>
                    <h6 className="list-group-item">Google Maps:</h6>
                    <GMaps />
                    <li className="list-group-item"></li>
                </ul>
            </div>
        </ div>
    )
}

export default Kontakt
