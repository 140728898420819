import React from 'react';


export default function AlpakaInfo() {
    return (
        <div className="randBox">
            <h3>Alpakas</h3>
            Das Alpaka zählt, ebenso wie das Lama, zur Familie der Kamele und zur Gattung der Neuweltkameliden. Sie wurden vor 5000-7000 Jahren in den Südamerikanischen Anden (Peru, Chile, Bolivien und Argentinien) von den Inkas, aus den Guanakos und nach neuesten genetischen Erkenntnissen auch aus den Vikunyas gezüchtet. In Europa wurden die Tiere zuerst in Zoos und später als Hobbytiere gehalten. Die Haltung dieser Tiere gewinnt allerdings Jahr für Jahr an Popularität. Alpakas sind Schwielensohler und laufen auf zwei Ledersohlen. Wie alle Kameliden kauen sie ihre Nahrung wieder, besitzen aber nur einen dreigeteilten Magen.
            <br /><br />
            Als Faserlieferant gezüchtet besitzt das Alpaka eine sehr feine Faser, die zu den edelsten Naturfasern zählt. Sie ist für ihre Weichheit, Feinheit und für einen herrlichen Glanz bekannt. Es kommen über 20 natürliche Farben vor. Die Farbpalette reicht von schwarz über braun und grau, bis zu einem reinen weiss. Es kommen auch gescheckte Tiere vor. Man unterscheidet zwei Typen: Das Huacaya und das Suri Alpaka. Beide sind gleich in Grösse und Gestalt. Ihr Unterschied liegt in der Beschaffenheit der Faserstruktur. Das Huacaya Alpaka hat ein dickes, fülliges Vlies mit feiner Krauselung (Crimp). Es sollte an Kopf und Beinen stark behaart sein. Das Suri Alpaka weist in der Faser einen Drall auf, die wie Locken am Tier herunterhängen. Der Faserdurchmesser ist feiner als bei Huacayas. Der Anteil der Suri an der Gesamtpopulation beträgt lediglich 10 %.
            <br /><br />
            Inzwischen erfreut sich Kleidung aus Alpakawolle auch bei uns wachsender Beliebtheit und die Nachfrage nach dieser wertvollen Faser steigt von Jahr zu Jahr. Dank der guten thermischen Beschaffenheit eignen sich die hergestellten Textilien sowohl für warme wie kalte Witterung. Alpakatextilien sind bequem zu tragen, unverwüstlich und Natur pur.
            <br /><br />
            Alpakas sind freundliche, robuste und anspruchslose Tiere mit einer Lebenserwartung von über 20 Jahren. Erwachsene Tiere wiegen zwischen 45-75 kg und haben eine Schulterhöhe von ca. 90 cm. Neugeborene Alpakas, genannt Crias, wiegen ca.6.5 kg. Sie werden nach ca.11.5 Monaten Tragzeit geboren. Die Geburt verläuft normalerweise problemlos am Tag und ohne fremde Hilfe. Einlingsgeburten sind die Regel.
            <br /><br />
            Alpakas haben große schwarze Augen mit einer besonders ausdruckstarken Ausstrahlung. Alpakas können spucken. Allerdings nutzen sie diese Waffe der Verteidigung äusserst selten. Am häufigsten kann man dieses Verhalten bei Futterneid der Tiere untereinander beobachten. Alpakas kommunizieren untereinander durch ihre Haltung, Bewegungen des Schwanzes und der Ohren sowie durch sanftes Brummen, das sehr gut zu Ihrem Charakter passt.
        </div>
    )
}
