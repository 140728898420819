import React from 'react'
import banner1 from "../../assets/Banner/Alpaka1.jpeg";
import banner2 from "../../assets/Banner/Sandra_hinter_alpaka.jpeg";
import banner3 from "../../assets/Banner/alpaka_gruppe.jpeg";
import banner4 from "../../assets/Banner/alpakas_rennen.jpeg";

const ImageViewer = () => {
    return (
        <div id="carouselExampleFade"
            className="carousel slide carousel-fade"
            data-bs-ride="carousel">
            <div className="carousel-inner">
                <div className="carousel-item active">
                    <div style={{ backgroundImage: `url(${banner1})` }} className="d-block w-100" />
                </div>
                <div className="carousel-item">
                    <div style={{ backgroundImage: `url(${banner2})` }} className="d-block w-100" />
                </div>
                <div className="carousel-item">
                    <div style={{ backgroundImage: `url(${banner3})`, marginTop: 0 }} className="d-block w-100" />
                </div>
                <div className="carousel-item">
                    <div style={{ backgroundImage: `url(${banner4})`, marginTop: 0 }} className="d-block w-100" />
                </div>
            </div>
            <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Previous</span>
            </button>
            <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Next</span>
            </button>
        </div>
    )
}

export default ImageViewer
