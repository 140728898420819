import React from 'react'

/*
    Für Buchungstermine und News-Meldungen
*/

export const DayToString = ({date}) => {
    const event = new Date(date);

        const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' };

        var datum = event.toLocaleDateString('de-DE', options);

    return (
        <div>
            {datum}
        </div>
    )
}

export default DayToString