import React from 'react'
import Preisliste from './Preisliste';

const Termine = () => {

    return (
        <div>
            <Preisliste />
        </div>
    )
}

export default Termine
