import React from 'react'

export const Alpaka_Schnuppern = () => {
    return (
        <div className="randBox" id="schnuppern">
            <h4 className="list-group-item">Alpaka Schnuppern</h4>
            <p className="mx-4">
                <li>Jeden Sonntag zwischen 11:00 Uhr bis 13:00 Uhr.</li>
                <li>Auf der Alpakawiese oder im Stall je nach Witterung inkl. Fütterung der Tiere Informationen zu den Tieren.
                    Hier werden alle Fragen bezüglich Alpakas gerne beantwortet.</li>
                <li>Pro Person 20,00 €</li>
                <li>Kinder unter 6 Jahren kostenlos</li>
            </p>
        </div>
    )
}

export default Alpaka_Schnuppern