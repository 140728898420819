import React from 'react'
import AlpakaInfo from './AlpakaInfo'
import HaltungInfo from './HaltungInfo'

function wissenswert() {
   return (
       <div>
           <AlpakaInfo/>
           <HaltungInfo/>
       </div>
   )
}

export default wissenswert