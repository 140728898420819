import React from 'react'

export const Alpaka_Fruehstueck = () => {
    return (
        <div className="randBox" id="fruehstueck">
            <h4 className="list-group-item">Alpaka Frühstück</h4>
            <p className="mx-4">
                <li>Beginnen Sie Ihren Sonntagmorgen anders als sonst, kommen Sie zu uns zu einem gemütlichen Frühstück mit einer Auswahl an Leckereien, sowie Kaffee oder Tee.</li>
                <li>Bei einer kleinen Hofführung erfahren Sie interessantes über das Alpakaleben. Sie lernen die sanftmütigen und zauberhaften Tiere mit dem soften Vlies und den großen Kulleraugen kennen.</li>
                <li>Sie erfahren, wie sozial Alpakas leben und können sich von diesen zauberhaften Tieren inspirieren lassen.</li>
                <li>Das Frühstück findet ausschließlich an Sonntagen statt.</li>
                <li>Kinder unter 14 benötigen einen volljährige Begleitperson.</li>
                <li>Pro Person 20,00 €</li>
                <li>Kinder unter 6 Jahren kostenlos</li>
            </p>
        </div>
    )

}