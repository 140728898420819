
// News/Mitteilungen
import Axios from "axios";

const host = "api"

export const addM = async (meldung) => {

    //console.log(meldung.img.name)

    Axios.post( host + '/neueMeldung', {
        titel: meldung.headline,
        extra: meldung.extra,
        text: meldung.text,
        datum: meldung.date,
        img: meldung.img
    }).then()

    //return data;
}

export const getMs = async () => {

    var data;

    await Axios.get(host +  '/meldungen').then((response) => data = response.data)

    return data;

}

export const delM = async (id) => {

    Axios.post(host + '/delMeldung', {
        id: id
    }).then()
}

// Eventplanung

export const addEvent = async (meldung) => {

    Axios.post(host + '/addEvent', {
        name: meldung.name,
        dauer: meldung.dauer,
        teilnehmer: meldung.teilnehmer,
        beschreibung: meldung.beschreibung
    }).then()

}

export const addPreis = async (meldung) => {

    Axios.post(host + '/addPreis', {
        kategorie: meldung.kategorie,
        name: meldung.preis,
        betrag: meldung.betrag,
        beschreibung: meldung.beschreibung
    }).then()

}

export const getEvents = async () => {
    var data;

    await Axios.get(host + '/events').then((response) => data = response.data)

    return data
}

export const delTour = async (id) => {

    Axios.post(host + '/delTermin', {
        id: id
    }).then()
}

export const delOldTour = async (id) => {

    Axios.post(host + '/delOldTermin', {
    }).then()
}

export const addTour = async (meldung) => {

    Axios.post(host + '/addTermin', {
        name: meldung.name,
        date: meldung.datum
    }).then()
}


// Termine/Buchungen

export const fetchTermine = async () => {

    var data;

    await Axios.get(host + '/termine').then((response) => data = response.data).then(() => console.log("succ"))

    return data

}

export const fetchPreisliste = async (meldung) => {

    var data;

    await Axios.post(host + '/preise', {
        name: meldung
    }).then((response) => data = response.data)

    return data
}

export const updateTermin = async (user) => {

    Axios.post(host + '/addUser', {
        vorname: user.vorname,
        nachname: user.nachname,
        anzahl: user.personen,
        tour_id: user.tour,
        preis_id: user.preisID
    });
}
