import React from 'react'

export const AGB = () => {
    return (
        <div>
            <div className="randBox">
                <h1>AGBs</h1>
                Reservierungen garantieren keine Teilnahme. Da Events verschoben oder abgesagt werden können.
                Erst mit der Bezahlung vor Ort wird ihr Anspruch rechtsgültig.
            </div>
            <div className="randBox">
                <h1>Datenschutzerklärung</h1>
                Die Behandlung sämtlicher Daten erfolgt im Rahmen der geltenden gesetzlichen Datenschutzbestimmungen. 
                Sie werden hiermit gemäß § 33 Abs.1 Bundesdatenschutzgesetz davon unterrichtet, dass Ihre personenbezogenen Daten in maschinenlesbarer Form gespeichert werden. 
                Eine Weitergabe an Dritte findet nicht statt. Sie können Ihre Einwilligung zur Speicherung und Nutzung Ihrer personenbezogenen Daten jederzeit mit Wirkung für die Zukunft widerrufen. 
                <br />
                Haftungshinweis: 
                <br />
                Trotz sorgfältiger inhaltlicher Kontrolle übernehmen wir keine Haftung für die Inhalte externer Links. Für den Inhalt der verlinkten Seiten sind ausschließlich deren Betreiber verantwortlich.
                <br />
                <br />
                Bei einer Buchung speichern wir Ihren Namen und Ihre Email Adreses.
                <br />
                Ihre Daten werden mit MailJS verarbeitet und eine Bestätigung über eine GMail Konto an Sie versendet.
                <br />
                Diese werden am Tag des Events automatish gelöscht.
                Sollten wir per Email in Kontakt treten werden wir diese, nach der Bearbeitung in spätestens 30 Tagen löschen.


                <br />
                <br />
                Sollen wir Ihre Daten früher Löschen schreiben Sie uns gerne eine Email an alpakas.am.forsterwald@gmail.com.
            </div>
            <div className="randBox">
                <h1>Impressum</h1>
                Sandra Schmitt<br />
                Waldhof 1<br />
                61206 Wöllstadt<br />
                alpakas.am.forsterwald@gmail.com<br />
                Diese Daten sind nicht vollständig und werden in Kürze ergänzt.
            </div>
        </div>
    )
}

export default AGB
