import React from 'react'
import { Alpaka_Schnuppern } from './Alpaka_Schnuppern'
import {Alpaka_Zeit} from './Alpaka_Zeit'
import {Alpaka_Spaziergang} from './Alpaka_Spaziergang'
import {Alpaka_Fruehstueck} from './Alpaka_Fruehstueck'
import {Alpaka_Patnerschaft} from './Alpaka_Patnerschaft'

export const Preislieste = () => {
    return (
        <div>
            <div className="randBox">
                <h2 align="center">Preisliste</h2>
            </div>
            <Alpaka_Schnuppern/>
            <Alpaka_Zeit />
            <Alpaka_Spaziergang />
            <Alpaka_Fruehstueck />
            <Alpaka_Patnerschaft />
        </div>
    )
}

export default Preislieste