import React from 'react'

export const Alpaka_Spaziergang = () => {
    return (
        <div className="randBox" id="spaziergang">
            <h4 className="list-group-item">Alpaka Spaziergang</h4>
            <p className="mx-4">
                <li>Eine kleine feine Runde um unseren Hof und in die Niddaauen.
                    Die Natur genießen, die Tiere auf sich wirken lassen und die Seele baumeln lassen.</li>
                <li>Etwa eine Stunde laufen wir gemütlich rund um den Waldhof in die Niddaauen.
                    Unsere Alpakas und Sie bestimmen das Tempo. Bitte planen sie genug Zeit ein.
                    Manchmal dauert so ein Spaziergang auch länger.</li>
                <li>Wenn wir zurückkommen, können die Tiere Futter bekommen, werden abge-
                    halftert und kommen zurück auf die Weide.</li>
                <li>Wir trinken dann gemütlich eine Tasse Kaffee oder Tee und sitzen noch ein wenig zusammen, falls die Wetterlage es erlaubt, können wir auch auf der Weide sitzen und dabei noch einiges über diese ruhigen und sanftmütigen Tiere erfahren.</li>
                <li>Kinder unter 12 benötigen einen erwachsenen Mitläufer.</li>
                <li>Pro Person 20,00 €</li>
                <li>Kinder unter 6 Jahren kostenlos</li>
            </p>
        </div>
    )
}