import React from 'react'

export const RegelnUndInformationen = () => {
    return (
        <div>
            <div className="randBox">
                <h2 align="center">Informationen und Regeln</h2>
            </div>
            <div className="randBox">
                <ul className="list-group list-group-flush">
                    <h4 className="list-group-item">Unsere Angebote:</h4>
                    <p className="mx-4">
                        <li>Barrierefreie Führungen, Privatführungen, Stallbegehung, Alpaka Picknick, Alpaka Fotoshooting und Alpaka Produkte</li>
                        <li>Beratung, Zucht und Verkauf von Alpakas</li>
                        <li>Wir sind Mitglied im Alpaka Zucht Verband Deutschland </li>
                    </p>
                    <h4 className="list-group-item">Regeln:</h4>
                    <p className="mx-4">
                        <li>Kein Futter mitbringen! Auch kein kleines Stück Karotte oder Apfel!</li>
                        <li>Alpakas sind Diabetiker, sie bekommen starke Bauchschmerzen und können daran sterben!</li>
                        <li>Und JA Alpakas spucken, aber normalerweise nicht auf Menschen, sondern nur untereinander, zum Beispiel um die Rangordnung herzustellen.</li>
                        <li>Keine Hunde. Auch nicht die kleinen süßen!</li>
                        <li>Nicht am Kopf streicheln, nur am Hals, manche Tiere mögen keinerlei Körperkontakt.</li>
                        <li>Bitte die Tiere nicht bedrängen. Sie kommen auf Euch zu, nicht umgekehrt.</li>
                        <li>Handy bitte auf lautlos stellen. Am besten ganz Zuhause lassen.</li>
                        <li>Fotos können gerne gemacht werden. Allerdings wenn wir laufen, laufen wir und die Handys müssen in den Taschen bleiben! </li>
                        <li>Den Führstrick niemals loslassen. Alpakas sind Fluchtiere und erschrecken auch mal, dann ist es wichtig den Strick gut mit beiden Händen festzuhalten. </li>
                        <li>Bitte den Strick nicht über den Hals der Tiere führen. Er sollte immer locker herunterhängen. </li>
                        <li>Kinder unter zwölf immer nur in Begleitung eines Erwachsenen.</li>
                        <li>Konzentriert Euch auf die Tiere und bleibt bitte zusammen.</li>
                        <li>Abstand von etwa einer Tierlänge ist wichtig. Alpakas können auch mal austreten!</li>
                        <li>Lasst die Tiere das Kommando/Tempo übernehmen. Stehenbleiben gehört auch dazu.</li>
                        <li>Bitte lasst Taschen, Regenschirme und solche Dinge am Stall, ihr braucht beide Hände.</li>
                        <li>Auf die Weide bitte nur in Begleitung einer autorisierten Person gehen.</li>
                        <li>Nehmt Rücksicht aufeinander.</li>
                    </p>

                </ul>
            </div>
        </div>
    )
}
