import React from 'react'
import ImageViewer from './ImageViewer'

const Startseite = () => {
    return (
        <>
            <ImageViewer />
            <div className="randBox">
                <h1>Herzlich Willkommen</h1>
                <h3>auf dem  Waldhof...</h3>
                <p>Lange haben wir überlegt, wie wir die großen Weideflächen um unseren Hof sinnvoll nutzen können. Bis wir eines Tages Alpakas kennengelernt haben. Sofort war die ganze Familie von diesen wundervollen Tieren begeistert. Weiches Fell, sanftes Wesen, und dieser Blick. Wir waren sofort verliebt.</p>
                <p>Besuchen Sie uns und unsere Tiere auf unserem Aussiedlerhof in der Wetterau. Estrella,Hobbit und Co. freuen sich auf Ihren Besuch. </p>
                <p></p>
            </div>
            <div className='randBox'>
                <h2>Warum Alpakas?</h2>
                <p><br />
                    Alpakas sind ruhig und friedfertig<br />

                    Alpakas sind intelligent und pflegeleicht<br />

                    Alpakas sind landwirtschaftliche Nutztiere<br />

                    Alpakas sind leicht zu züchten<br />

                    Alpakas gibt es in vielen Farben, einfarbig, gescheckt oder getupft<br />

                    Alpakas sind robust und widerstandsfähig<br />

                    Alpakas sind sicher im Umgang mit Menschen, auch mit Kindern<br />

                    Alpakas sind sehr gute Futterverwerter und Landschaftspfleger<br />

                    Alpakas sind angenehme und ruhige Zeitgenossen<br /><br />

                    kurzum einfach tolle Tiere!
                </p>
            </div>
        </>
    )
}

export default Startseite
