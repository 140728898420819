export const GMaps = () => {
  return (
    <iframe
      src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d8578.660152989965!2d8.75481093280214!3d50.26483855818808!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1sw%C3%B6llstadt%20alpakas%20am%20forsterwald!5e0!3m2!1sde!2sde!4v1641810570062!5m2!1sde!2sde"
      height="600"
      style={{ border: 0 }}
      allowFullScreen=""
      loading="lazy">
    </iframe>
  )
}
