import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Router, Route } from "react-router-dom";

import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Startseite from "./components/startseite/Startseite";
import Termine from "./components/termine/Termine";
import News from "./components/news/News";
import Tiere from "./components/tiere/Tiere";
import Kontakt from "./components/kontakt/Kontakt";
import Wissenswert from "./components/wissenswert/Wissenswert";
import AGB from "./components/AGB";
import Cookies from "./components/cookies";
import { RegelnUndInformationen } from "./components/termine/RegelnUndInformationen";



function App() {

  /*>>>
  
    Navebar <- Navigationsleiste oben

    div mit header ist ein kleiner Abstand am Kopf der Seite damit dieser nicht von Navbar verdeckt wird

    Route leidet zu den Unterseiten ohne zu laden weiter

    Footer mit Copyright und AGBs

  <<<*/

  return (
    <Router>
      <div className="App">

        <Navbar />

        <Route path='/' exact component={Startseite} />
        <Route path='/news' component={News} />
        <Route path='/kontakt' component={Kontakt} />
        <Route path='/termine' component={Termine} />
        <Route path='/informationen' component={RegelnUndInformationen} />
        <Route path='/tiere' component={Tiere} />
        <Route path='/wissenswertes' component={Wissenswert} />
        <Route path='/agbs' component={AGB} />

        <Cookies />

        <Footer />
      </div>
    </Router>

  );
}

export default App;
