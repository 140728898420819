import React from 'react'

export const Alpaka_Zeit = () => {
    return (
        <div className="randBox" id="zeit">
            <h4 className="list-group-item">Alpaka Zeit</h4>
            <p className="mx-4">
                <li>Eine Stunde auf der Alpakawiese entspannen, beobachten, fotografieren und genießen die Tiere hautnah zu sehen.</li>
                <li>Ist auch für kleinere Kinder geeignet, da die Tiere sehr sicher sind, vor allem im Umgang mit Kindern.</li>
                <li>Termine nach Vereinbarung.</li>
                <li>Pro Person 15€</li>
            </p>
        </div>
    )

}