import React from 'react'
import CookieConsent from "react-cookie-consent";

export const cookies = () => {
    return (
        <CookieConsent
          buttonText="Cookies akzeptieren.">
          Diese Seite benutzt ausschließlich funktionale Cookies.
          Weiter Infos in den <a href="/agbs">AGBs</a>.
        </CookieConsent>
    )
}

export default cookies
